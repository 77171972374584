import React from 'react';
import _, { isEmpty } from 'lodash';
import { useTranslation } from 'react-i18next';
import { useLocation, useHistory } from 'react-router-dom';
import { SideBar, theme, useApi } from '@fivehealth/botero';
import { useAppData } from 'context/AppDataContext';
import { tweakPageName } from 'AppUtils';

import {
  faHomeLgAlt,
  faUserFriends,
  faUser,
  faClipboardList,
  faQuestionCircle,
  faEnvelope,
  faHandHoldingHeart,
  faUsers,
  faCalendarStar,
  faCreditCard,
} from '@fortawesome/pro-regular-svg-icons';

import { faPlusCircle } from '@fortawesome/pro-solid-svg-icons';
import EVENTS from 'constants/events';
import useCaregiverUsers from 'hooks/useCaregiverUsers';
import BOTMD_LOGO_SMALL from '../../assets/logo-small.svg';
import BOTMD_LOGO_LARGE from '../../assets/logo-large.svg';

const Sidebar = (props) => {
  const history = useHistory();
  const location = useLocation();
  const { t } = useTranslation();

  const {
    queries: { useClinicCalendars, useClinic, useClinicGroup },
  } = useApi({
    queries: ['useClinicCalendars', 'useClinic', 'useClinicGroup'],
  });

  const { data: clinicCalendars } = useClinicCalendars();
  const { data: clinicData } = useClinic();
  const { data: clinicGroupData } = useClinicGroup();
  const { user, clinic, clinician, event, toggleSidebar, sidebarOpened } =
    useAppData();

  const { caregiverRoleUid } = useCaregiverUsers({
    variables: {},
  });

  const showMonitoringTab = !isEmpty(
    _.get(clinic, 'allPatientFormsTableSettings')
  );

  const enrollmentFormUrl = _.get(clinic, 'enrollmentForm.url');
  const pageName = location.pathname.split('/')[1];

  const handleMenuClick = (item) => {
    history.push(item.path);
  };
  const headers = [
    {
      id: 'botmd',
      logo: (expand) => (expand ? BOTMD_LOGO_LARGE : BOTMD_LOGO_SMALL),
    },
  ];

  let menus = [
    {
      id: 'overview',
      testId: 'overviewMenu',
      label: t('Overview'),
      icon: faHomeLgAlt,
      onClick: handleMenuClick,
      path: '/overview',
      logEventProps: {
        subSource: 'SideBar',
        eventName: EVENTS.LIST_OVERVIEW,
        page: tweakPageName(pageName),
      },
    },
    showMonitoringTab && {
      id: 'patientMonitoring',
      testId: 'patientMonitoringMenu',
      label: t('Patient Monitoring'),
      icon: faClipboardList,
      onClick: handleMenuClick,
      path: '/patient-monitoring',
      logEventProps: {
        page: tweakPageName(pageName),
        subSource: 'SideBar',
        eventName: EVENTS.LIST_PATIENT_MONITORING,
      },
    },
    {
      id: 'patientList',
      testId: 'patientListMenu',
      label: t('Patient List'),
      icon: faUserFriends,
      onClick: handleMenuClick,
      path: '/patient-list',
      logEventProps: {
        page: tweakPageName(pageName),
        subSource: 'SideBar',
        eventName: EVENTS.LIST_PATIENT_LIST,
      },
    },
    {
      id: 'patientGroups',
      testId: 'patientGroupsMenu',
      label: t('Patient Groups'),
      icon: faUsers,
      onClick: (item) => {
        handleMenuClick(item);
        event?.emit('patient-groups');
      },
      path: '/patient-groups',
      logEventProps: {
        page: tweakPageName(pageName),
        subSource: 'SideBar',
        eventName: EVENTS.LIST_PATIENT_GROUPS,
      },
    },
    clinicCalendars?.length > 0 && {
      id: 'patientAppointments',
      testId: 'patientAppointments',
      label: t('Appointments'),
      icon: faCalendarStar,
      onClick: (item) => {
        handleMenuClick(item);
        event?.emit('appointments');
      },
      path: '/appointments',
      logEventProps: {
        page: tweakPageName(pageName),
        subSource: 'SideBar',
        eventName: EVENTS.LIST_PATIENT_GROUPS,
      },
    },
    !!clinician?.isCaregiver === false &&
      caregiverRoleUid && {
        id: 'caregivers',
        testId: 'caregiver_list',
        label: t('Family Members'),
        icon: faHandHoldingHeart,
        onClick: handleMenuClick,
        path: '/caregiver-list',
        logEventProps: {
          subSource: 'SideBar',
          eventName: EVENTS.PATIENT_CAREGIVERS,
          page: tweakPageName(pageName),
        },
      },
    !!clinician?.isCaregiver === false && {
      id: 'clinicalAdmin',
      testId: 'clinicalAdminMenu',
      label: t('Clinical Admin'),
      icon: faUser,
      onClick: handleMenuClick,
      path: '/clinical-admins',
      logEventProps: {
        page: tweakPageName(pageName),
        subSource: 'SideBar',
        eventName: EVENTS.LIST_CLINICAL_ADMIN,
      },
    },
  ];

  if (clinicData?.billingSettings || clinicGroupData?.billingSettings) {
    menus.push({
      id: 'billing',
      testId: 'billingMenu',
      label: t('Billing'),
      icon: faCreditCard,
      onClick: (item) => {
        handleMenuClick(item);
      },
      path: '/billing',
      logEventProps: {
        page: tweakPageName(pageName),
        subSource: 'SideBar',
        eventName: EVENTS.LIST_BILLING,
      },
    });
  }
  menus = menus.filter((m) => m);

  let additionalMenus = [
    !!clinician?.isCaregiver === false && {
      id: 'addPatient',
      testId: 'AddPatient_',
      label: t('Add new patient'),
      path: enrollmentFormUrl ?? '',
      isLink: true,
      target: '_blank',
      icon: faPlusCircle,
      logEventProps: {
        page: tweakPageName(pageName),
        subSource: 'SideBar',
        eventName: EVENTS.ADD_NEW_PATIENT,
      },
      onClick: () => window.open(enrollmentFormUrl),
      iconProps: {
        color: theme.colors.primary,
      },
      labelProps: {
        color: theme.colors.primary,
        fontWeight: 600,
      },
      toolTipElementProps: {
        color: 'white',
      },
    },
  ];
  additionalMenus = additionalMenus.filter((item) => item);

  const footers = [
    {
      id: 'helpGuide',
      testId: 'helpGuideMenu',
      logEventProps: {
        page: tweakPageName(pageName),
        subSource: 'SideBar',
        eventName: EVENTS.READ_HELP_GUIDE,
      },
      label: t('Help Guide'),
      icon: faQuestionCircle,
      onClick: () =>
        window.open(
          'https://help.botmd.com/hc/en-us/categories/11090457704719-Bot-MD-Care-Knowledge-Base'
        ),
      iconProps: {
        color: theme.colors.darkestShade,
      },
      labelProps: {
        color: theme.colors.darkestShade,
        fontWeight: 600,
      },
      toolTipElementProps: {
        color: 'white',
      },
    },
    {
      id: 'contactUs',
      testId: 'contactUsMenu',
      label: t('Contact Us'),
      icon: faEnvelope,
      onClick: () => {
        const CUSTOMER_SUPPORT_EMAIL = 'support@botmd.io';
        const url = new URL(`mailto:${CUSTOMER_SUPPORT_EMAIL}`);
        url.searchParams.set(
          'subject',
          encodeURIComponent(`${clinic.name} - ${clinician.name}`)
        );
        window.open(decodeURI(url.toString()));
      },
      iconProps: {
        color: theme.colors.darkestShade,
      },
      labelProps: {
        color: theme.colors.darkestShade,
        fontWeight: 600,
      },
      toolTipElementProps: {
        color: 'white',
      },
      logEventProps: {
        subSource: 'SideBar',
        eventName: EVENTS.OPEN_CONTACT_US,
      },
    },
  ];

  return (
    <SideBar
      defaultExpand={sidebarOpened}
      activeTab={
        _.chain(menus)
          .filter((menu) => location.pathname.match(new RegExp(menu.path, 'i')))
          .first()
          .get('id')
          .value() || 'overview'
      }
      headers={headers}
      menus={!user?.isPatientFacing ? menus : []}
      additionalMenus={!user?.isPatientFacing ? additionalMenus : []}
      footers={footers}
      onExpandCallback={toggleSidebar}
      {...props}
    />
  );
};

export default Sidebar;
